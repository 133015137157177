@import "../../assets//styles/variables";

.admin-login {
  .logo {
    margin-bottom: 1.5rem;
  }
}

.card {
  border: none !important;
}
